import { Link } from "react-router-dom"
import { portfolios } from "../../Utilities/constants"
import styles from "../../style"
import PorfolioBtn from "./PorfolioBtn"
import PortfolioCard from "./PortfolioCard"

const PortfolioTestimonial = () => {
  return (
    <section id="portfolio" className={`${styles.paddingY} ${styles.flexCenter} flex-col relative`}>
    <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient"/>

    <div className="w-full flex justify-between items-center md:flex-row
    flex-col sm:mb-16 mb-6 relative z-[1]">
      <h1 className={styles.heading2}>
        Build a Strong Investment <br className="sm:block hidden"/> Portfolio with Us.
      </h1>
      <div className="w-full md:mt-0 mt-6">
        <p className={`${styles.paragraph} max-w-[450px]`}>
         This is a long term investment program where we offer the best of Returns of over 10% Monthly and 120% Yearly,
         we offer portfolio plans for 2 years, 3 years, 5 years, 7 years, and 10 years depending on how long you would like to invest.
         The most important part of our portfolio plan is that your account doubles every year, So after every twelve months of investing the account doubles, and likewise your monthly ROI also doubles in percentage.
         Emergency payout will only be permitted for those who are taking out yearly payouts or one time payouts.
        Further information and details would be given to you after registration or you could call any of our call lines to know more.
        </p>
        <button type="button" className={`py-5 px-5 bg-gold
            font-poppins font-medium text-[18px] text-primary outline-none ${styles} rounded-[10px]`}>
            <Link to="/register">Register Now</Link>
        </button>
      </div>
    </div>
    
    <div className="flex flex-wrap sm:justify-start justify-center 
    w-full feedback-container relative z-[1]">
      {portfolios.map((card) => (
        <PortfolioCard key={card.id} {...card} />
      ))}
    </div>
  </section>
  )
}

export default PortfolioTestimonial
