import styles from "../../style"
import Button from "./Button"

const CTA = () => (
  <section id="getstarted" className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} 
  sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
     <div className="flex-1 flex flex-col">
      <h2 className={styles.heading2}>
        Let's get you started now!
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        They say "Time waits for no man", the longer you wait the further a great oppurtunity gets away from you. We are a registered trading company, wait no further and increase your wealth potentials with us.
      </p>
     </div>

     <div className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10`}>
      <Button />
     </div>
  </section>
)

export default CTA
