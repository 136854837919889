import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC3qYUgZBntkWSUiKSoDsvOP-TvBhkptkc",
  authDomain: "degoldings-540b7.firebaseapp.com",
  projectId: "degoldings-540b7",
  storageBucket: "degoldings-540b7.appspot.com",
  messagingSenderId: "85321357149",
  appId: "1:85321357149:web:94aaeac4d78fff1c20fab4",
  measurementId: "G-GDS4PJPFNK"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore();