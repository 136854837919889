import { card } from "../../assets"
import styles, { layout } from "../../style"
import InvestBtn from "./InvestBtn"

const CardDeal = () => (
  <section id="investing" className={layout.section}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        Simple and Flexible <br className="sm:block hidden"/> Trading Programs.
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
      We value simplicity, efficiency, and productivity, therefore getting started with us takes just a few steps, You Register, Pick a Trading plans, Get a call from one of our Experts who would put you through the processess, your options and every other information that you may need, then you invest and we make you rich and richer...
      </p>

      <InvestBtn styles='mt-10' />
    </div>

    <div className={layout.sectionImg}>
      <img src={card} alt="card" className="w-[100%] h-[100%]"/>
    </div>
  </section>
)

export default CardDeal
