import { facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "about",
    title: "About",
  },
  {
    id: "trading",
    title: "Trading",
  },
  {
    id: "plans",
    title: "Plans",
  },
  {
    id: "portfolio",
    title: "Programs",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Expertise and Knowledge",
    content:
      "We offer our client and trading community access to market insights, and expert advices, helping clients make informed investment decisions and providing our community with quality trading signals and informations.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Top Notch Risk Management",
    content:
      "We help clients diversify their trading portfolios, providing risk management strategies to protect our client's capital, and to minimize potential losses.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Tech Enhanced Trading Options",
    content:
      "We develop and use advanced trading platforms and tools that ensure faster and more efficient execution of trades, providing a huge optimization to the high returns our strategies generate.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "25% Interest.",
    name: "3 Months Plan",
    title: "One Time Payout (25% ROI)",
    img: shield,
  },
  {
    id: "feedback-2",
    content:
      "50% Interest + 5% Bonus.",
    name: "6 Months Plan",
    title: "Monthly Payout (8.3%) / ",
    title2: "One Time Payout (50% ROI)",
    img: shield,
  },
  {
    id: "feedback-3",
    content:
      "100% Interest + 10% Bonus.",
    name: "One Year Plan",
    title: "Monthly Payout (8.3%) / ",
    title2: "One Time Payout (100% ROI)",
    img: shield,
  },
];

export const portfolios = [
  {
    id: "feedback-1",
    content:
    "Monthly Payout (10%).",
    content1:
    "Quaterly Payout (40%).",
    content2:
    "6 Months Payout (60%).",
    content3:
    "One Year Payout (120%).",
    name: "200% Returns",
    title: "2 Years Portfolio",
    img: shield,
  },
  {
    id: "feedback-2",
    content:
    "Monthly Payout (10%).",
    content1:
    "Quaterly Payout (40%).",
    content2:
    "6 Months Payout (60%).",
    content3:
    "One Year Payout (120%).",
    name: "300% Returns",
    title: "3 Years Portfolio",
    img: shield,
  },
  {
    id: "feedback-3",
    content:
    "Monthly Payout (10%).",
    content1:
    "Quaterly Payout (40%).",
    content2:
    "6 Months Payout (60%).",
    content3:
    "One Year Payout (120%).",
    name: "500% Returns",
    title: "5 Years Portfolio",
    img: shield,
  },
  {
    id: "feedback-4",
    content:
    "Monthly Payout (10%).",
    content1:
    "Quaterly Payout (40%).",
    content2:
    "6 Months Payout (60%).",
    content3:
    "One Year Payout (120%).",
    name: "700% Returns",
    title: "7 Years Portfolio",
    img: shield,
  },
  {
    id: "feedback-4",
    content:
    "Monthly Payout (10%).",
    content1:
    "Quaterly Payout (40%).",
    content2:
    "6 Months Payout (60%).",
    content3:
    "One Year Payout (120%).",
    name: "1000% Returns",
    title: "10 Years Portfolio",
    img: shield,
  },
];

export const trading = [
  {
    id: "feedback-1",
    content:
    "Classes: Three Times a Week.",
    content1:
    "Duration: Five Weeks.",
    content2:
    "Free PDF on trading strategies, and Live Trading.",
    content3: 
    "Advanced Trading Certificate.",
    name: "Advanced Trading Class",
    title: "$250",
    img: shield,
  },
  {
    id: "feedback-1",
    content:
    "Classes: Three Times a Week.",
    content1:
    "Duration: Seven Weeks.",
    content2:
    "Four PDFs on strategies, Risks, Psychology, Volume Spread Analysis, Live Trading.",
    content3:
    "One on One Interactive Sessions.",
    content4: 
    "Advanced Trading Certificate.",
    name: "Mentorship Program",
    title: "$500",
    img: shield,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Assurance",
    value: "100%",
  },
  {
    id: "stats-2",
    title: "Secure",
    value: "100%",
  },
  {
    id: "stats-3",
    title: "Hours of Support",
    value: "24+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Home",
        link: "home",
      },
      {
        name: "What do we do",
        link: "about",
      },
      {
        name: "Trading",
        link: "trading",
      },
      {
        name: "Programs",
        link: "investing",
      },
    ],
  },
  {
    title: "Plans",
    links: [
      {
        name: "Trading Plans",
        link: "plans",
      },
      {
        name: "Trading Classes",
        link: "portfolio",
      },
    ],
  },
  {
    title: "Get Started",
    links: [
      {
        name: "Call To Action",
        link: "getstarted",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/de_goldings?igshid=YzU1NGVIODEzOA==",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/profile.php?id=61551852372038",
  },
  {
    id: "social-media-2",
    icon: linkedin,
    link: "https://www.linkedin.com/in/d-e-goldings-9a806b295",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/DEGoldings?t=bvsi_dWiUKD7fJ-RfXEOtw&s=09",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];