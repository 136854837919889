import Home from './Pages/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Success from './Alerts/Success';
import Invest from './Pages/Invest'
import Trading from './Pages/Trading';
import Register from './Pages/Register'
import ScrollToTop from './Utilities/ScrollToTop';
import { AnimatePresence } from 'framer-motion';
import Contact from './Pages/Contact';
import ContactSuccess from './Alerts/ContactSuccess';
import ComingSoon from './Alerts/Coming/ComingSoon';

const App = () => (
  <AnimatePresence mode="wait" initial={false}>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/'>
          <Route index element={<Home />} />
          <Route path='/success' element={<Success />} />
          <Route path='/comingsoon' element={<ComingSoon />} />
          <Route path='/register' element={<Register />} />
          <Route path='/invest' element={<Invest />} />
          <Route path='/trading' element={<Trading />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/contactsuccess' element={<ContactSuccess />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </AnimatePresence>
);

export default App
