import React from 'react'
import { logo } from '../../assets'
import styles from '../../style'
import { Link } from 'react-router-dom'
import { motion as m } from 'framer-motion'

const BackNav = () => {
  return (
    <nav className='w-full flex py-4 justify-between items-center navbar'>
      <img src={logo} alt="degoldings" className='w-[135px] h-[50px]'/>
      <m.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95, rotate: "2.5deg" }} 
        transition={{
          duration: 0.125,
          ease: "easeInOut",
        }} 
        type='button' className={`py-3 px-3 m-1 bg-gold
                font-poppins font-medium text-[18px] text-primary outline-none ${styles} rounded-[10px]`}>
                <Link to="/" className='text-white'>Back to home</Link>
    </m.button>
    </nav>
  )
}

export default BackNav
