import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles, {layout} from '../style'
import BackNav from '../components/Main/BackNav'
import { motion as m } from 'framer-motion'
import Confetti from 'react-confetti'

const Success = () => {
    const [pieces, setPieces] = useState(500);

    const stopConfetti = () => {
      setTimeout(() => {
        setPieces(0);
      }, 3000);
    };

    useEffect(() => {
      stopConfetti();
    }, [])

    const location = useLocation()
  return (
    <m.section initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='bg-primary h-screen items-center justify-center'>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <BackNav />
        </div>
      </div>
        <div className="max-w-4xl p-6 mx-auto items-center justify-center bg-indigo-600 rounded-md shadow-md dark:bg-gray-800 mt-20">
            <h1 className="text-xl font-bold text-white capitalize dark:text-white">Hello {location.state.values.fullname} 👋</h1>
            <p className='text-lg text-gray-500'>Thank you for registering with us today, we will send you an email over at <span className='text-dimWhite'>{location.state.values.email}</span> with further details, also, you will get a call from us on your mobile <span className='text-dimWhite'>{location.state.values.contact}</span> right away to get you started on your financial journey with us. Be rest assured we are happy to have you aboard.</p>
        </div>

      <Confetti gravity={0.2} numberOfPieces={pieces}/>         

    </m.section> 
  )
}

export default Success
