import React from "react"
import { Link } from "react-router-dom"
import { motion as m } from 'framer-motion'

const Button = ({ styles }) => {
  return (
    <m.button 
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95, rotate: "2.5deg" }} 
      transition={{
        duration: 0.125,
        ease: "easeInOut",
      }}
      type="button" 
      className={`py-5 px-5 bg-gold
        font-poppins font-medium text-[18px] text-primary outline-none ${styles} rounded-[10px]`}>
      <Link to="/register">Register Now</Link>
    </m.button>
  )
}

export default Button