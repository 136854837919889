import styles from '../style'
import { motion as m} from 'framer-motion'

import { Navbar, Hero, Stat, Business, Billing, CardDeal, Testimonials,
   Clients, CTA, Footer } from '../components';
import Portfolio from '../components/Main/Portfolio';
import Cards from '../components/Main/Cards';
import VideoComponent from '../components/Main/VideoComponent'

const Home = () => (
    <m.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='bg-primary w-full overflow-hidden'>
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
        <Navbar />
        </div>
    </div>

    <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
        <Hero />
        </div>
    </div>

    <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
        <Stat />
        <Business />
        <Billing />
        <CardDeal />
        <Testimonials />
        <Portfolio />
        <Cards />
        <Clients />
        <CTA />
        <VideoComponent />
        <Footer />
        </div>
    </div>

    </m.div>
)

export default Home
