import styles from "../../style"
import { logo } from "../../assets"
import { footerLinks, socialMedia } from "../../Utilities/constants"
import { Link } from "react-router-dom"

const Footer = () => (
  <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
      <div className="flex-1 flex flex-col jsutify-start mr-10">
        <img src={logo} alt="degoldings" className="w-[266px] h-[72px] object-contain" />
        <p className={`${styles.paragraph} mt-4 max-w-[310px]`}>
          Register with us today and start getting exciting trading content. 
          <br /> WhatsApp: <a className="text-gold text-[15px]" href="https://wa.me/message/D5XINABQQUFMA1">Send Us A Message</a> 
          <br />Call Center: <span className="text-[15px]">+234 706 3500 944.</span>
          <br /><Link to="/contact" className="text-gold text-[15px]">Tap to Contact us</Link></p>
      </div>

      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
        {footerLinks.map((footerLink) => (
          <div key={footerLink.key} className="flex flex-col ss:my-0 my-4 min-w-[150px]">
            <h4 className="font-poppins font-medium text-[18px]
                leading-[27px] text-white cursor-pointer">
              {footerLink.title}
            </h4>
            <ul className="list-none mt-4">
              {footerLink.links.map((link, index) => (
                <li key={link.name} className={`font-poppins font-normal text-[16px]
                leading-[24px] text-dimWhite hover:text-secondary cursor-pointer 
                ${index !== footerLink.links.length - 1 ? 'mb-4' : 'mb-0'} `}> <a href={`#${link.link}`}>{link.name}</a></li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>

    <div className="w-full flex justify-between items-center 
    md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
      <p className="font-poppins font-normal text-center text-[18px]
        leading-[27px] text-white cursor-pointer">
        2023 DEGOLDINGS. All Rights Reserved.
      </p>
      <div className="flex flex-row md:mt-0 mt-6">
        {socialMedia.map((social, index) => (
          <a href={social.link}>
            <img 
          key={social.id} 
          src={social.icon} 
          alt={social.id}
          className={`w-[21px] h-[21px] object-contain cursor-pointer
          hover-text-dimWhite
          ${index !== socialMedia.length - 1 ? 'mr-6' : 'mr-0'}`} />
          </a>
        ))}
      </div>
    </div>
  </section>
)

export default Footer
