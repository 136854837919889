import React from 'react'
import styles from '../style'
import BackNav from '../components/Main/BackNav'
import InvestCardDeal from '../components/Main/InvestCardDeal'
import InvestTestimonial from '../components/Main/InvestTestimonial'
import PortfolioTestimonial from '../components/Main/PortfolioTestimonial'
import { motion as m } from 'framer-motion'

const Invest = () => (
  <m.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='bg-primary w-full overflow-hidden'>
  <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <BackNav />
      </div>
  </div>

  <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <InvestCardDeal />
          <InvestTestimonial />
          <PortfolioTestimonial />
        </div>
    </div>
  </m.div>
  )

export default Invest
