import React from 'react'
import styles from '../style'
import BackNav from '../components/Main/BackNav'
import { Billing } from '../components'
import PortfolioTrading from '../components/Main/PortfolioTrading'
import { motion as m } from 'framer-motion'

const Trading = () => {
  return (
    <m.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}className='bg-primary w-full overflow-hidden'>
  <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <BackNav />
      </div>
  </div>

  <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Billing />
          <PortfolioTrading />
        </div>
    </div>
  </m.div>
  )
}

export default Trading
