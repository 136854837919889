import React, { useRef, useEffect, useState } from 'react';
import styles from '../../style';
import Video from '../../assets/video.mp4'

const VideoComponent = () => {
    const videoRef = useRef(null);
    const [shouldPlay, setShouldPlay] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
          const rect = videoElement.getBoundingClientRect();
          const isVideoInView = rect.top <= window.innerHeight && rect.bottom >= 0;
  
          if (isVideoInView && !shouldPlay) {
            // Video is in view and it's not playing, so play it
            videoElement.play().then(() => {
              setShouldPlay(true);
            });
          } else if (!isVideoInView && shouldPlay) {
            // Video is out of view and it's playing, so pause it
            videoElement.pause();
            setShouldPlay(false);
          }
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [shouldPlay]);

  return (
    <div className=" items-center justify-center bg-primary">
      <h2 className={styles.heading2}>Join the Family</h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-2 mb-2`}>
        We would love to have you onboard, to learn, grow and become a better, Financially speaking!!
        </p>
      <video ref={videoRef}
        className="w-full md:w-2/3 lg:w-1/2 h-auto"
        controls>
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* Additional content after the video */}
    </div>
  );
};

export default VideoComponent;
