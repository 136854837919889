import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import styles, { layout } from "../style"
import { useFormik } from 'formik'
import * as Yup from 'yup';
import BackNav from '../components/Main/BackNav';
import { socialMedia } from '../Utilities/constants';
import { db } from '../Firebase/firebase'
import { collection, addDoc } from "firebase/firestore"; 
import { motion as m } from 'framer-motion'


const Register = () => {

  const [err, setErr] = useState("");

  const navigate = useNavigate()

  // Formik Logics
  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      date: "",
      gender: "",
      country: "",
      address: "",
      contact: "",
      plan: "",
      description: "",
    },

    //Validate Form
    validationSchema: Yup.object({
      fullname: Yup.string()
      .max(35, 'Full Name must be 35 characters or less.')
      .required("Full Name is required"),
      email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
      date: Yup.date()
      .required("Date of Birth is required"),
      gender: Yup.string()
      .required("Gender is required"),
      country: Yup.string()
      .required("Country of Origin is required"),
      address: Yup.string()
      .required("Address is required"),
      contact: Yup.string()
      .required("Contact info is required"),
      plan: Yup.string()
      .required("Purpose of joining is required"),
    }),

    // Submit Form
    onSubmit: (values) => {
      console.log(values)

      const docRef = addDoc(collection(db, "clients"), {
        fullname: values.fullname,
        email: values.email,
        date: values.date,
        gender: values.gender,
        country: values.country,
        address: values.address,
        contact: values.contact,
        plan: values.plan,
        description: values.description,
      }).then((docRef) => {
        navigate("/success", {state:{values}})
      }).catch((err) => {
        setErr(true);
      })
    }
  })

return (
  <m.section initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className="bg-primary w-full overflow-hidden py-2">
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <BackNav />
        </div>
    </div>
    <div className="max-w-4xl p-6 mx-auto items-center justify-center bg-indigo-600 rounded-md shadow-md dark:bg-gray-800 mt-20">
    <h1 className="text-xl font-bold text-white capitalize dark:text-white">Let's Get Started 👋</h1>
    <p className='text-lg text-gray-500'>Join our Platform today and invest in a future with limitless opportunities.</p>
    <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
                <label className={`${formik.touched.fullname && formik.errors.fullname ? "text-red-400" : "text-white dark:text-gray-200"}`}
                 htmlFor="fullname">{formik.touched.fullname && formik.errors.fullname ? formik.errors.fullname : "Full Name"}</label>
                <input id="fullname" type="text" name='fullname' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Enter your Full Name' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
            </div>

            <div>
                <label className={`${formik.touched.email && formik.errors.email ? "text-red-400" : "text-white dark:text-gray-200"}`}
                 htmlFor="email">{formik.touched.email && formik.errors.email ? formik.errors.email : "Email Address"}</label>
                <input id="email" type="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Enter your Email Address' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
            </div>

            <div>
                <label className={`${formik.touched.date && formik.errors.date ? "text-red-400" : "text-white dark:text-gray-200"}`}
                 htmlFor="date">{formik.touched.date && formik.errors.date ? formik.errors.date : "Date of Birth"}</label>
                <input id="date" type="date" value={formik.values.date} onChange={formik.handleChange} onBlur={formik.handleBlur} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
            </div>

            <div>
                <label className={`${formik.touched.gender && formik.errors.gender ? "text-red-400" : "text-white dark:text-gray-200"}`}
                 htmlFor="gender">{formik.touched.gender && formik.errors.gender ? formik.errors.gender : "Gender"}</label>
                <select name='gender' placeholder='Choose your gender' value={formik.values.gender} onChange={formik.handleChange} onBlur={formik.handleBlur} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring">
                    <option>Default</option>
                    <option>Male</option>
                    <option>Female</option>
                </select>
            </div>

            <div>
                <label className={`${formik.touched.country && formik.errors.country ? "text-red-400" : "text-white dark:text-gray-200"}`}
                 htmlFor="country">{formik.touched.country && formik.errors.country ? formik.errors.country : "Country"}</label>
                <select name='country' placeholder='Choose your country' value={formik.values.country} onChange={formik.handleChange} onBlur={formik.handleBlur} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring">
                    <option>Default</option>
                    <option>Nigeria</option>
                    <option>United States</option>
                    <option>United Kingdom</option>
                    <option>Canada</option>
                    <option>Australia</option>
                </select>
            </div>

            <div>
                <label className={`${formik.touched.address && formik.errors.address ? "text-red-400" : "text-white dark:text-gray-200"}`}
                 htmlFor="address">{formik.touched.address && formik.errors.address ? formik.errors.address : "Resident Address"}</label>
                <input id="address" type="text" value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Enter your Resident Address' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
            </div>

            <div>
                <label className={`${formik.touched.contact && formik.errors.contact ? "text-red-400" : "text-white dark:text-gray-200"}`}
                 htmlFor="contact">{formik.touched.contact && formik.errors.contact ? formik.errors.contact : "Contact"}</label>
                <input id="contact" type="tel" value={formik.values.contact} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='Enter your Contact' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
            </div>

            <div>
                <label className={`${formik.touched.plan && formik.errors.plan ? "text-red-400" : "text-white dark:text-gray-200"}`} 
                htmlFor="plan">{formik.touched.plan && formik.errors.plan ? formik.errors.plan : "What do you hope to achieve on our Platform"}</label>
                <select name='plan' value={formik.values.plan} onChange={formik.handleChange} onBlur={formik.handleBlur} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring">
                    <option>Default</option>
                    <option>Learn to Trade</option>
                    <option>Trade Funds (Invest)</option>
                  </select>
            </div>

            <div>
                <label className="text-white dark:text-gray-200" htmlFor="description">Please Give us a brief description about yourself</label>
                <textarea id="textarea" name='description' type="textarea" value={formik.values.description} onChange={formik.handleChange} placeholder='What do you do, your current position etc.' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"></textarea>
            </div>

            {/* <div>
                <label className="block text-sm font-medium text-white">
                Passport Photo
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-white" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <span className="">Upload a Passport</span>
                      <input id="file-upload" name="passport" value={formik.values.passport} onChange={formik.handleChange} type="file" className="sr-only"/>
                    </label>
         s          </div>
                  <p className="text-xs text-white">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div> */}
        </div>

        {err && <span>Something went wrong!</span>}

        <div className="flex justify-end mt-6">
            <button type='submit' className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-200 hover:text-primary m-2 focus:outline-none focus:bg-gray-600">Submit</button>
        </div>
    </form>
    </div>

    <div className="w-full flex justify-between items-center 
    md:flex-row flex-col pt-6 p-2">
      <p className="font-poppins font-normal text-center text-[18px]
        leading-[27px] text-white cursor-pointer">
        2023 DEGOLDINGS. All Rights Reserved.
      </p>
      <div className="flex flex-row md:mt-0 mt-6">
        {socialMedia.map((social, index) => (
          <a href={social.link}>
            <img 
          key={social.id} 
          src={social.icon} 
          alt={social.id}
          className={`w-[21px] h-[21px] object-contain cursor-pointer
          hover-text-dimWhite
          ${index !== socialMedia.length - 1 ? 'mr-6' : 'mr-0'}`} />
          </a>
        ))}
      </div>
    </div>
</m.section>
 )
}

export default Register
