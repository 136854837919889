import {features} from '../../Utilities/constants'
import styles, { layout } from '../../style'

const FeatureCard = ({ icon, title, content, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px]
  ${index !== features.length -1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
      <img src={icon} alt="icon" className='w-[50%] h-[50%] object-contain' />
    </div>
    <div className='flex-1 flex flex-col ml-3'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p 
      className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px] mb-1'>
        {content}
      </p>
    </div>
  </div>
)

const Business = () => {
  return (
    <section
      id='about' className={layout.section}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>You do the business, <br 
        className='sm:block hidden'/> 
        we'll handle the money.</h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        In the world of today, the old adage "time is money" couldn't be more accurate,
        entrusting the management of your money to us experts through smart trading programs and strategies can increase your wealth, while freeing up valuable time and resources, 
        allowing you to focus on what you do best—growing your business
        </p>

        <button type="button" className={`py-5 px-5 bg-gold
          font-poppins font-medium text-[18px] text-primary outline-none ${styles} rounded-[10px]`}>
          <a href="#getstarted">Get Started</a>
      </button>
      </div>

      <div className={`${layout.sectionImg} flex-col`}>  
        {features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} 
          index={index}/>
        ))}
      </div>
    </section>
  )
}

export default Business