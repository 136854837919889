import { feedback } from "../../Utilities/constants"
import styles from "../../style"
import FeedbackCard from './FeedBackCard'
import InvestBtn from "./InvestBtn"

const InvestTestimonial = () => {
  return (
    <section id="plans" className={`${styles.paddingY} ${styles.flexCenter} flex-col relative`}>
    <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient"/>

    <div className="w-full flex justify-between items-center md:flex-row
    flex-col sm:mb-16 mb-6 relative z-[1]">
      <h1 className={styles.heading2}>
        Find the right <br className="sm:block hidden"/> Investment Plan for you.
      </h1>
      <div className="w-full md:mt-0 mt-6">
        <p className={`${styles.paragraph} max-w-[450px]`}>
          Our Investment programs are short term based that comes with 8.3% Monthly Return of Investment, 
          we offer three types of investment programs; 3 Month, 6 Month, 
          and 12 Month programs. Our 3 Month program comes with a one time payout system, whereas the 6 months 
          and 12 months programs allow both a monthly payout of 8.3% and a one time payout as desired by our client.
        </p>
      </div>
    </div>
    
    <div className="flex flex-wrap sm:justify-start justify-center 
    w-full feedback-container relative z-[1]">
      {feedback.map((card) => (
        <FeedbackCard key={card.id} {...card} />
      ))}
    </div>
  </section>
  )
}

export default InvestTestimonial
