import React from 'react'
import { quotes } from '../../assets'

const FeedBackCard = ({ content, name, title, title2, img }) => (
  <div className='flex justify-between flex-col px-10 py-12 rounded-[20px]
  max-w-[370px] md:mr-10 sm:mr-5 mr-0 my-5 feedback-card'>
    <img src={quotes} alt="quotes" className='w-[40px] h-[25px] object-contain'/>
    <p className='
    font-poppins font-normal text-[16px] leading-[30px] text-white mt-5 my-2'>{content}</p>

    <div className='flex flex-row'>
      <img src={img} alt="avatar" className='w-[45px] h-[45px] rounded-full'/>

      <div className='flex flex-col ml-4'>
        <h4 className='font-poppins font-semibold text-[18px] leading-[30px] text-white'>
          {name}
        </h4>
        <p className='
        font-poppins font-normal text-[15px] leading-[22px] text-dimWhite'>
          {title}</p>
          <p className='
        font-poppins font-normal text-[15px] leading-[22px] text-dimWhite'>
          {title2}</p>
      </div>
    </div>
  </div>
)

export default FeedBackCard
