import styles from '../../style'
import {discount, robot} from '../../assets'
import GetStarted from './GetStarted'
import { motion as m } from 'framer-motion'

const Hero = () => (
    <section
      id='home' className={`flex md:flex-row flex-col ${styles.paddingY}`}>
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
        <div className='flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2'>
          <img src={discount} alt="discount" className='w-[32px] h-[32px] '/>
          <p className={`${styles.paragraph} ml-2`}>
            <span className='text-white'>Up to</span> 100% returns {" "}
            <span className='text-white'>Yearly + Special Bonuses</span>
          </p>
        </div>

        <div className='flex flex-row justify-between items-center w-full'>
          <h1 className='flex-1 font-poppins font-semibold ss:text-[64px] text-[45px] 
          text-white ss:leading-[100px] leading-[75px]'>
            This is <br className='sm:block hidden' /> {" "}
            <span
            className='text-gold'> Where Smart People
            </span> {" "}
          </h1>
          <div className='ss:flex hidden md:mr-4 mr-0'>
            <GetStarted/>
          </div>
        </div>

        <h1 className='font-poppins font-semibold ss:text-[66px] text-[50px] 
        text-white ss:leading-[100px] leading-[75px] w-full'>
          Come to Trade
        </h1>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          We are a technology driven company that trades securities for our clients, manage funds,
           scale up profitable trading, as well as offer strong training systems to our community.
        </p>
      </div>

      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
        <img src={robot} alt="billing" 
        className='w-[100%] h-100% relative z-[5]' />

        <div className='absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient' />
        <div className='absolute z-[1] w-[80%] h-[80%] rounded-full bottom-40 white__gradient' />
        <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient' />
      </div>
      <div className={`ss:hidden ${styles.flexCenter}`}>
          <GetStarted />
      </div>
    </section>
)

export default Hero
