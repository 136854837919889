import React from 'react'
import './Coming.css'
import styles from '../../style'
import BackNav from '../../components/Main/BackNav'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const ComingSoon = () => {
  return (
    <section className='bg-primary h-screen items-center justify-center'>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <BackNav />
        </div>
      </div>

      <div className="max-w-4xl p-6 mx-auto flex flex-col gap-10 items-center justify-center bg-indigo-600 rounded-md shadow-md dark:bg-gray-800 mt-20">
            <div className='text-center'>
              <h1 className="text-xl font-bold text-white capitalize dark:text-white">We are almost there 👋</h1>
              <p className='text-lg text-gray-500'>Stay tuned for the launch of our amazing blog :)</p>
            </div>
            <FlipClockCountdown
              className='flip-clock'
              to={new Date().getTime() + 168 * 3600 * 1000 + 5000}
              labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
              duration={0.5}
            />
      </div>
    </section>
  )
}

export default ComingSoon

