import {apple, bill, google} from '../../assets'
import styles, { layout } from '../../style'

const Billing = () => (
    <section id='trading' className={layout.sectionReverse}>
      <div className={layout.sectionImgReverse}>
        <img src={bill} alt="billing" className='w-[100%] h-[100%] relative z-[5] '/>
        <div className='absolute z-[3] -left-1/2 top-0 w-[50%] 
        h-[50%] rounded-full white-gradient '/>
        <div className='absolute z-[0] -left-1/2 bottom-0 w-[50%] 
        h-[50%] rounded-full pink-gradient '/>
      </div>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>Trading Experts and
        <br className='sm:block hidden'/> Advanced Technologies.</h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          We are Experts in financial market, with diverse options ranging from stocks, to the Forex Market and various other commodities, we've built and developed quality signals and improved strategies through years of experience and research, now implementing cutting edge technology and Algorithms to improve our productivity and maximise our potentials in taking more profitable trades. Our platform is open to our community of students to learn and gain the experience of profitable trading.
        </p>

        <div className='flex flex-row flex-wrap sm:mt-6'>
          <img src={apple} alt="apple_store" className='
          w-[128px] h-[42px] object-contain mr-5 cursor-pointer'/>
          <img src={google} alt="play_store" className='
          w-[128px] h-[42px] object-contain cursor-pointer'/>
         <p className={`${styles.paragraph} max-w-[470px] ml-2 mt-2`}>
            Coming Soon</p>
        </div>
      </div>
    </section>
  )

export default Billing