import React from 'react'
import styles from '../../style'
import PortfolioCard from './PortfolioCard'
import { trading } from '../../Utilities/constants'

const PortfolioTrading = () => {
  return (
    <section id="portfolio" className={`${styles.paddingY} ${styles.flexCenter} flex-col relative`}>
    <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient"/>

    <div className="w-full flex justify-between items-center md:flex-row
    flex-col sm:mb-16 mb-6 relative z-[1]">
      <h1 className={styles.heading2}>
        Start your Financial Journey <br className="sm:block hidden"/> with us today.
      </h1>
      <div className="w-full md:mt-0 mt-6">
        <p className={`${styles.paragraph} max-w-[450px]`}>
         This is a new lifestyle, the life of profitable trading, Dominating in the financial market, Forex, Cryptocurrency, Synthetic Indices, and the Stock Market. Join our team of experienced traders, analysts and trading experts for Weekly webinars, Seminars, Mentorship, Free Signals and Life changing opportunities in the financial market. We also offer Paid Mentorship classes to help you boost your trading journey.
        </p>
      </div>
    </div>
    
    <div className="flex flex-wrap sm:justify-start justify-center 
    w-full feedback-container relative z-[1]">
      {trading.map((card) => (
        <PortfolioCard key={card.id} {...card} />
      ))}
    </div>
  </section>
  )
}

export default PortfolioTrading
