import { feedback } from "../../Utilities/constants"
import styles from "../../style"
import FeedbackCard from './FeedBackCard'
import InvestBtn from "./InvestBtn"

const Testimonials = () => (
  <section id="plans" className={`${styles.paddingY} ${styles.flexCenter} flex-col relative`}>
    <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient"/>

    <div className="w-full flex justify-between items-center md:flex-row
    flex-col sm:mb-16 mb-6 relative z-[1]">
      <h1 className={styles.heading2}>
        Find the right <br className="sm:block hidden"/> Trading Plan for you.
      </h1>
      <div className="w-full md:mt-0 mt-6">
        <p className={`${styles.paragraph} max-w-[450px]`}>
          Everyone has a limit to how much they are willing to risk and how far they will go,
          We believe that no matter what your choice is, we can handle it. Our Trading Programs are short term with three plans, We also offer Structural Portfolio Building plans and programs.
        </p>
        <InvestBtn styles="mt-10" />
      </div>
    </div>
    
    <div className="flex flex-wrap sm:justify-start justify-center 
    w-full feedback-container relative z-[1]">
      {feedback.map((card) => (
        <FeedbackCard key={card.id} {...card} />
      ))}
    </div>
  </section>
)

export default Testimonials
