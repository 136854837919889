import React, {useRef} from 'react'
import {motion as m} from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import styles from '../style'
import emailjs from '@emailjs/browser';

const Contact = () => {
    const navigate = useNavigate()

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6w900dw', 'template_jikcad4', form.current, 'aGn_ezZaZMlQjly-f')
        .then(() => {
          navigate("/contactsuccess")
        },
        );
    };

  return (
    <section className="bg-white dark:bg-gray-900">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
            <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a technical issue? Want to send feedback about our platform? Need details about our Trading plans of Financial classes? Let us know.</p>
            <form ref={form} onSubmit={sendEmail} className="space-y-8">
                <div>
                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
                    <input type="text" name="user_name" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="What is your name...?" required />
                </div>
                <div>
                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                    <input type="email" name="user_email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Enter Email" required />
                </div>
                <div class="sm:col-span-2">
                    <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                    <textarea id="message" name='message' rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="What would you like to tell us...?"></textarea>
                </div>
                <div className=' flex justify-between'>
                    <button type="submit" value="Send" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Send message</button>
                    <m.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95, rotate: "2.5deg" }} 
                        transition={{
                        duration: 0.125,
                        ease: "easeInOut",
                        }} 
                        type='button' className={`py-2 px-2 m-1 bg-gold
                                font-poppins font-medium text-[18px] text-primary outline-none ${styles} rounded-[10px]`}>
                                <Link to="/" className='text-white'>Back to home</Link>
                    </m.button>
                </div>
            </form>
        </div>
    </section>
  )
}

export default Contact
